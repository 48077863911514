<template>
  <div class="p-grid">
    <Toast position="top-right" />
    <div class="p-col-12">
      <br />
      <div class="card">
        <h5>Mon Entreprise</h5>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <label for="companyName">Nom de l'entreprise <span class="p-text-bold text-red">*</span></label>
            <InputText :disabled="true" v-model="nom" id="companyName" type="text" />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="companyName">E-mail de l'entreprise <span class="p-text-bold text-red">*</span></label>
            <InputText
              :class="{
                'p-invalid': !email && email.indexOf('@') == -1 && email.indexOf('.') == -1 && submitted,
              }"
              :disabled="false"
              v-model="email"
              id="companyName"
              type="text"
              maxlength="40"
            />
            <h6 v-if="!email && submitted" style="color: red">Veuillez renseigner l'e-mail de l’entreprise.</h6>
            <h6
              v-if="
                (email && submitted && email.indexOf('@') == -1) || (email && submitted && email.indexOf('.') == -1)
              "
              style="color: red"
            >
              Veuillez respecter le format du champ « E-mail de l’entreprise »
            </h6>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="date-creation-id">Date de création <span class="p-text-bold text-red">*</span></label>
            <Calendar
              id="date-creation-id"
              v-model="annee"
              disabled="true"
              :showIcon="true"
              dateFormat="dd/mm/yy"
              :maxDate="today"
              placeholder="JJ/MM/AAAA"
            />
            <h6 v-if="!annee && submitted" style="color: red">
              Veuillez renseigner la date de création de l’entreprise.
            </h6>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="adresse">Adresse <span class="p-text-bold text-red">*</span></label>
            <InputText
              :class="{ 'p-invalid': !address && submitted }"
              v-model="address"
              id="adresse"
              type="text"
              maxlength="100"
            />
            <h6 v-if="!address && submitted" style="color: red">Veuillez renseigner le champ « Adresse ».</h6>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label :class="{ 'p-invalid': isOnWeb && submitted }" for="adresse">Site web </label>
            <InputText
              v-model="www"
              :class="{ 'p-invalid': isOnWeb && submitted }"
              placeholder="www.exemple.com"
              id="adresse"
              type="text"
              maxlength="40"
            />
            <h6 v-if="isOnWeb && submitted" style="color: red">
              Veuillez respecter le format du champ « Site web » (Exemple : www.site.com).
            </h6>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <div v-bind:style="{ display: 'flex', flexDirection: 'row' }">
              <label>Numéro d'identification fiscal <span class="p-text-bold text-red">*</span></label>
              <div class="p-grid">
                <div
                  class="p-col-12"
                  v-bind:style="{
                    marginLeft: 15 + 'px',
                    height: '29px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }"
                >
                  <i
                    class="pi pi-info-circle"
                    v-tooltip.top="
                      'Identification fiscale : L’identification fiscale est le numéro d\'identification attribué par les administrations fiscales lors de la création de votre entreprise. Par exemple, au Sénégal le numéro est composé de 7 caractères numériques et d’un suffixe de 3 caractères alphanumériques (ex : 0001234 2G3). En France, le numéro est composé de 9 chiffres pour le SIREN et pour le SIRET, les 9 chiffres du SIREN suivi de 5 chiffres propres à l’’établissement. En Angleterre, le numéro correspond au NIF et il est composé de 9 chiffres.'
                    "
                  />
                </div>
              </div>
            </div>
            <InputText :disabled="true" v-model="NIE" id="idCompany" type="text" maxlength="50" />
            <h6 v-if="!NIE && submitted" style="color: red">
              Veuillez renseigner le champ « Numéro d'identification fiscal ».
            </h6>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="state">Pays <span class="p-text-bold text-red">*</span></label>
            <Dropdown
              id="state"
              v-model="pays"
              disabled="true"
              :options="countries"
              optionLabel="name"
              placeholder="Sélectionner un pays"
            ></Dropdown>
            <h6 v-if="!pays && submitted" style="color: red">Veuillez renseigner le champ.</h6>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <div v-bind:style="{ display: 'flex', flexDirection: 'row' }">
              <label for="regimeCommerce">Registre de commerce <span class="p-text-bold text-red">*</span></label>
              <div class="p-grid">
                <div
                  class="p-col-12"
                  v-bind:style="{
                    marginLeft: 15 + 'px',
                    height: '29px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }"
                >
                  <i
                    class="pi pi-info-circle"
                    v-tooltip.top="
                      'Registre de commerce : Le registre du commerce et des sociétés répertorie toutes les entreprises commerciales. Par exemple, au Sénégal, cela correspond au NINEA qui est composé de 7 caractères numériques (ex : 0001234). En France, le numéro est composé de la mention RCS, la ville d’immatriculation, une lettre A pour les commerçants et B pour les sociétés et le numéro SIREN de l’entreprise à 9 chiffres. En Angleterre, le numéro est composé de 8 chiffres ou 2 lettres suivies de 6 chiffres (ex : 01234567 ou LP123456).'
                    "
                  />
                </div>
              </div>
            </div>
            <InputText :disabled="true" v-model="regime" id="regimeCommerce" type="text" maxlength="50" />
            <h6 v-if="!regime && submitted" style="color: red">
              Veuillez renseigner le champ « Registre de commerce ».
            </h6>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="domaineMetier">Domaine(s) métier(s) <span class="p-text-bold text-red">*</span></label>
            <MultiSelect
              v-model="domaine"
              :options="domaines.data"
              id="domaine-metier-ids"
              optionValue="id"
              optionLabel="libelle"
              :multiple="true"
              placeholder="Choisir"
              :filter="true"
              class="multiselect-custom"
            >
              <template #value="slotProps">
                <div v-for="d of slotProps.value" :key="d">
                  <div>
                    {{ domaines.data?.find((dm) => dm.id === d)?.libelle }}
                  </div>
                </div>
              </template>
            </MultiSelect>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <div class="p-field">
              <label for="fj">Forme juridique <span class="p-text-bold text-red">*</span></label>
              <Dropdown
                id="fj"
                :disabled="true"
                v-model="formeJuridique"
                :options="formesJuridiques"
                optionLabel="libelle"
                placeholder="Sélectionner une forme juridique"
              ></Dropdown>
              <h6 v-if="!formeJuridique && submitted" style="color: red">
                Veuillez renseigner le champ « Forme juridique ».
              </h6>
            </div>
            <div class="p-field">
              <div>
                <label for="fj">Vous êtes enregistré en tant que : </label>
                <span class="p-ml-2">{{ findAndReturnTitle(user?.entreprise.rangSouscriptionEnCours) }}</span>
              </div>
              <!-- <div
                v-bind:style="{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  height: '27px',
                }"
              >
                <label v-bind:style="{ marginBottom: '6px' }">{{ libelleForm }}</label>
              </div> -->
            </div>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <div class="p-inputgroup">
              <label for="statutCompany">Logo de l'entreprise :</label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span class="p-inputgroup-addon">
                <i class="pi pi-paperclip"></i>&nbsp;&nbsp;&nbsp;
                <input
                  v-if="fileLogo == null || changeLogo || !haveLogo"
                  label="Choisir une fichier  "
                  type="file"
                  accept=".png,.jpeg,.jpg"
                  @change="onUploadLogo"
                />
                <button
                  style="max-width: 20em"
                  v-if="haveLogo && !changeLogo"
                  @click="openDoc(fileLogo, 'Logo de  l\'entreprise')"
                >
                  Voir le fichier
                </button>
                <button :disabled="true" v-if="haveLogo && !changeLogo" @click="cancelLogo">Annuler</button>
              </span>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for=""><i class="pi pi-folder"></i> Historique des paiements</label>
            <div>
              <div v-for="abonnement in user?.entreprise.abonnements" :key="abonnement.id">
                <!-- TODO: Validation a refactoriser. -->
                <!-- v-show="
                    index < abonnement.length - 1 ||
                    (index === user?.entreprise.abonnements.length - 1 && user?.entreprise.isTmpValid)
                  " -->
                <span v-if="isPaymentValidated(abonnement)">
                  - Reçu de paiement du {{ getFormattedDate(abonnement.datePaiement) }}
                  <a style="margin-left: 50px; cursor: pointer" @click="downloadReceipt(abonnement.id)"
                    ><i class="pi pi-download"></i
                  ></a>
                </span>
              </div>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-7">
            <br />
            <label>Pièces Jointes <span class="p-text-bold text-red">*</span></label>
            <p class="p-text-italic">Formats acceptés : .jpeg, .png, .pdf</p>
            <h6 v-if="!fileNIE && submitted" style="color: red">
              Veuillez joindre une pièce jointe dans le champ « Document d’identification fiscale ».
            </h6>
            <h6 v-if="!fileRegistre && submitted" style="color: red">
              Veuillez joindre une pièce jointe dans le champ « Document Registre de commerce ».
            </h6>
            <h6 v-if="errorOnFileType && submitted" style="color: red">
              Veuillez joindre des pièces uniquement au format <b>pdf</b>, <b>jpeg</b> et <b>png</b>.
            </h6>

            <div class="p-field p-col-12">
              <br />
              <div class="p-inputgroup">
                <label
                  for="idCompany"
                  v-bind:style="{
                    display: flex,
                    flexDirection: column,
                    alignContent: center,
                  }"
                  >Document d’identification fiscal <span class="p-text-bold text-red">*</span> :</label
                >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="p-inputgroup-addon">
                  <i class="pi pi-paperclip"></i>&nbsp;&nbsp;&nbsp;
                  <input
                    :disabled="true"
                    v-if="fileNIE == null || changeNIE || !haveNIE"
                    label="Choisir une fichier  "
                    type="file"
                    accept=".pdf,.png,.docx"
                    @change="onUploadNIE"
                  />
                  <button
                    style="max-width: 20em"
                    v-if="haveNIE && !changeNIE"
                    @click="openDoc(fileNIE, 'Document d\'identification fiscal')"
                  >
                    Voir le fichier
                  </button>
                  <button :disabled="true" @click="cancelNIE">Annuler</button>
                </span>
              </div>
            </div>
            <div class="p-field p-col-12">
              <div class="p-inputgroup">
                <label for="regCompany">Registre de commerce <span class="p-text-bold text-red">*</span> :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="p-inputgroup-addon">
                  <i class="pi pi-paperclip"></i>&nbsp;&nbsp;&nbsp;
                  <input
                    :disabled="true"
                    v-if="fileRegistre == null || changeRegistre || !haveRegistre"
                    label="Choisir une fichier  "
                    type="file"
                    accept=".pdf,.png,.docx"
                    @change="onUploadRegistre"
                  />
                  <button
                    style="max-width: 20em"
                    v-if="haveRegistre && !changeRegistre"
                    @click="openDoc(fileRegistre, 'Registre de commerce')"
                  >
                    Voir le fichier
                  </button>
                  <button :disabled="true" v-if="haveRegistre && !changeRegistre" @click="cancelRegistre">
                    Annuler
                  </button>
                </span>
              </div>
            </div>

            <div class="p-field p-col-12">
              <div class="p-inputgroup">
                <label for="statutCompany">Statut de l'entreprise <span class="p-text-bold text-red">*</span> :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="p-inputgroup-addon">
                  <i class="pi pi-paperclip"></i>&nbsp;&nbsp;&nbsp;
                  <input
                    :disabled="true"
                    v-if="fileStatut == null || changeStatut || !haveStatut"
                    label="Choisir un fichier  "
                    type="file"
                    accept=".pdf,.png,.docx"
                    @change="onUploadStatus"
                  />
                  <button
                    style="max-width: 20em"
                    v-if="haveStatut && !changeStatut"
                    @click="openDoc(fileStatut, 'Statut de  l\'entreprise')"
                  >
                    Voir le fichier
                  </button>
                  <button v-if="haveStatut && !changeStatut" :disabled="true" @click="cancelStatus">Annuler</button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <Button v-bind:style="{ width: '25%' }" @click="back" label="Retour" class="p-mr-2 p-mb-2" />
        <!-- 
          v-if="role=='ROLE_TEMP'||role=='USER_VALIDATE'||role=='ROLE_TEMP_REJETE'"
        -->
        <Button
          v-bind:style="{ width: '25%' }"
          :loading="loadings[0]"
          v-if="!(role == 'ROLE_TEMP_REJETE')"
          @click="update"
          label="Enregistrer les informations"
          class="p-mr-2 p-mb-2"
        />

        <Button
          v-if="role == 'ROLE_TEMP_REJETE'"
          v-bind:style="{ width: '25%' }"
          @click="setBack"
          label="Resoumettre l'inscription"
          class="p-mr-2 p-mb-2"
        />

        <h6 v-if="isError" style="color: red">
          Tous les champs avec une étoile (<span class="p-text-bold text-red">*</span>) sont obligatoires.
        </h6>
        <h6 v-if="errorOnSubmit" style="color: red">Une erreur est survenue. Veuillez rééssayer plus tard.</h6>
        <h6 v-if="isOnWeb && submitted" style="color: red">Le format du site web est incorrect.</h6>
      </div>
    </div>
    <FileViewer
      v-if="null !== fileViewer.url"
      :url-link="fileViewer.url"
      :original-url="fileViewer.originalUrl"
      :title="fileViewer.title"
      v-on:close="onCloseFileViewer()"
    />
  </div>
</template>
<script>
import moment from 'moment';
import FileViewer from '@/components/FileViewer';
import EnumFormatMixin from '@/mixins/enum-format';
import http from '../services/http.service';
import DateFormatMixin from '../mixins/date-format';
import LoggedUserMixin from '../mixins/logged-user';

export default {
  mixins: [EnumFormatMixin, DateFormatMixin, LoggedUserMixin],
  components: {
    FileViewer,
  },
  data() {
    return {
      fileViewer: {
        url: null,
        originalUrl: null,
        title: null,
      },
      today: new Date(),
      company: null,
      user: null,
      id: undefined,
      nom: '',
      email: '',
      address: '',
      NIE: '',
      regime: '',
      annee: '',
      www: '',
      formeJuridique: null,
      formesJuridiques: null,
      countries: null,
      pays: null,
      loading: [false, false],
      display: [false, false],
      upHere: false,
      domaines: [],
      domaine: [],
      token: '',
      fileRegistre: null,
      fileNIE: null,
      fileStatut: null,
      fileLogo: null,
      changeStatut: false,
      changeLogo: false,
      changeNIE: false,
      changeRegistre: false,
      haveStatut: false,
      haveLogo: false,
      haveNIE: false,
      haveRegistre: false,
      config: {},
      complete_info_user: false,
      iscomplete: false,
      submitted: false,
      isOnWeb: false,
      errorOnNom: false,
      errorOnAddress: false,
      errorOnNIE: false,
      errorOnRegime: false,
      errorOnAnnee: false,
      errorOnPays: false,
      errorOnDomaine: false,
      errorOnFileType: false,
      errorOnSubmit: false,
      isError: false,
      previewImage: null,
      displayDialog: false,
      role: '',
      fileName: '',
      fileURL: '',
      libelleForm: '',
      typeForm: '',
      selectButtonValues: [
        { name: 'Oui', code: 'O' },
        { name: 'Non', code: 'N' },
      ],
      selectButtonValue: null,
      tabReponses: [],
      rank: null,
      price: null,
      isrank: false,
      rankisgood: false,
      loadings: [false, false],
      errorOnRank: false,
      hasDomaine: false,
    };
  },
  async mounted() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      this.getProfil();
      this.getDomaines();
      this.getNations();
      this.getFormJuridique();
    } else {
      this.$router.push('/login');
      // console.log('No User Logged Here');
    }
  },
  watch: {
    domaine: {
      handler: function (domaine) {
        if (domaine) {
          if (domaine.length > 0) {
            this.hasDomaine = true;
          }
        } else {
          this.hasDomaine = false;
        }
      },
    },
  },
  methods: {
    findAndReturnTitle(code) {
      let title = '';

      switch (code) {
        case 'MAJOR':
          title = 'Opérateur titulaire du titre minier';
          break;
        case 'RANK_UN':
          title = 'Sous-traitant de rang un (1)';
          break;
        case 'RANK_DEUX':
          title = 'Sous-traitant de rang deux (2)';
          break;
        case 'ETRANGER':
          title = 'Prestataire étrangère ou consultant étranger';
          break;
        case 'DROIT_SENEGALAIS':
          title = 'Prestataire de droit Sénégalais';
          break;
        case 'LOCAL':
          title = 'Prestataire local';
          break;
      }
      return title;
    },
    onCloseFileViewer() {
      this.fileViewer.url = null;
      this.fileViewer.title = null;
      this.fileViewer.originalUrl = null;
    },
    async downloadReceipt(abonnementId) {
      await http
        .get(`/abonnements/${abonnementId}/receipt.pdf`, {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));
          var docUrl = document.createElement('a');

          docUrl.href = FILE;
          docUrl.setAttribute('download', `recu-de-paiement-${abonnementId}.pdf`);
          document.body.appendChild(docUrl);
          docUrl.click();
        });
      //.catch((e) => console.log(e));
    },
    openDoc(url, title) {
      url = url.includes('localhost') ? url : 'https' + url.substr(4);
      const token = localStorage.getItem('token');
      http
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        })
        .then((response) => {
          const blobType = url.substr(-4);
          const blob = new Blob([response.data], {
            type: blobType === '.pdf' ? 'application/pdf' : 'image/png',
          });
          this.fileViewer.url = URL.createObjectURL(blob);
          this.fileViewer.originalUrl = url;
          this.fileViewer.title = title;
        })
        .catch(console.error);
    },
    addrep() {
      if (this.rank === null) {
        this.tabReponses.push(this.selectButtonValue.code);
        // console.log('tableau: ' + this.tabReponses);
        this.form();
      }
    },
    preview() {
      // console.log('tableau: ' + this.tabReponses);
      this.tabReponses = [];
      this.rank = null;
      this.price = null;
      this.rankisgood = false;
      this.isrank = false;
      this.initForm();
    },
    async initForm() {
      if (!this.rank) {
        await http
          .post('/enterprises/decisionClassification', { answersChain: ['x'] }, this.config)
          .then((reponse) => {
            this.libelleForm = reponse.data.libelle + ' *';
            this.typeForm = reponse.data.typeEntity;
          })
          .catch(() => {
            // console.log('------------------' + error);
          });
      } else {
        this.libelleForm =
          'Vous êtes enregistré en tant que ' +
          this.enumMap[this.rank] +
          '. ' +
          'Vous devez payer : ' +
          this.price?.toLocaleString() +
          ' Francs CFA.';
        // console.log(this.libelleForm);
      }
    },
    async form() {
      if (!this.rank) {
        await http
          .post('/enterprises/decisionClassification', { answersChain: this.tabReponses }, this.config)
          .then((reponse) => {
            if (reponse.data.typeEntity === 'QUESTION') {
              this.rankisgood = false;
              this.libelleForm = reponse.data.libelle + ' *';
              this.typeForm = reponse.data.typeEntity;
            } else {
              this.rankisgood = true;
              this.price = reponse.data.amount;
              this.rank = reponse.data.rank;
              this.libelleForm =
                'Vous êtes enregistré en tant que ' +
                this.enumMap[this.rank] +
                '. ' +
                'Vous devez payer : ' +
                this.price?.toLocaleString() +
                ' Francs CFA.';
              console.log(this.libelleForm);
            }
          })
          .catch(() => {
            // console.log('------------------' + error);
          });
      } else {
        this.libelleForm =
          'Vous êtes enregistré en tant que ' +
          this.enumMap[this.rank] +
          '. ' +
          'Vous devez payer : ' +
          this.price?.toLocaleString() +
          ' Francs CFA.';
      }
    },
    async confirmform() {
      const data = {
        rangSouscriptionEnCours: this.rank,
        montantSouscriptionEnCours: this.price,
      };
      await http
        .post('/enterprises/setClassification', data, this.config)
        .then(() => {
          this.isrank = true;
          // console.log(JSON.stringify(res.data));
        })
        .catch((e) => console.log('ERROR IN SETTING RANK: ' + e));
    },
    async getProfil() {
      //try {
      const res = await http.get('/users/getProfile', {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      this.company = res.data.entreprise;

      const splittedCreatedAtDate = this.company.companyCreatedAt?.split('-');

      this.user = res.data;
      this.id = this.company.id;
      this.nom = this.company.name;
      this.address = this.company.address;
      this.NIE = this.company.identificationNumber;
      this.annee =
        splittedCreatedAtDate == null
          ? null
          : `${splittedCreatedAtDate[2]}/${splittedCreatedAtDate[1]}/${splittedCreatedAtDate[0]}`;
      this.email = this.company.email == null ? res.data.email : res.data.entreprise.email;
      //console.log("*****************EMAIL =>"+this.email)
      this.www = this.company.www;
      //console.log("*****************WWW =>"+this.company.www)
      this.pays = this.company.pays;
      this.formeJuridique = this.company.formeJuridique;
      this.regime = this.company.tradeRegister;
      this.domaine = this.company.domaineMetiers.map((dm) => dm.id);
      // console.log('d : ' + JSON.stringify(this.domaine));
      if (JSON.stringify(this.domaine) == '[]') {
        this.hasDomaine = false;
      } else {
        this.hasDomaine = true;
      }
      this.fileRegistre = this.company.registreCommerce;
      this.fileNIE = this.company.numeroIdentification;
      //console.log("*****************NIE =>"+this.fileNIE)
      this.fileStatut = this.company.statusEntreprise;
      this.fileLogo = this.company.logo;
      this.rank = this.company.rangSouscriptionEnCours;
      //console.log("*****************RANG =>"+this.rank)
      this.price = this.company.montantSouscriptionEnCours;
      if (this.rank) {
        //console.log('************the error is not here***********'+this.rank)
        this.libelleForm =
          'Vous êtes enregistré en tant que ' +
          this.enumMap[this.rank] +
          '. ' +
          'Vous devez payer : ' +
          this.price?.toLocaleString() +
          ' Francs CFA.';
      } else {
        //console.log('************the error is here***********')
        this.initForm();
      }
      //console.log('USER RANK IS ' + this.rank + ' FOR : ' + this.price);
      if (this.fileLogo) {
        this.haveLogo = true;
      }
      if (this.fileStatut === null || this.fileStatut === undefined) {
        this.changeStatut = true;
      } else {
        this.haveStatut = true;
      }
      if (this.fileRegistre === null || this.fileRegistre === undefined) {
        this.changeRegistre = true;
      } else {
        this.haveRegistre = true;
      }
      if (this.fileNIE === null || this.fileNIE === undefined) {
        this.changeNIE = true;
      } else {
        this.haveNIE = true;
      }
      const lastindex = res.data.roles.length - 1;
      this.role = res.data.roles[lastindex]?.name;

      //console.log('*******STATUT :   ' + JSON.stringify(this.company.statusEntreprise.fileName));
      // } catch (e) {
      //  console.log('ERROR FROMS 2 => ' + e);
      //}
    },
    async update() {
      //console.log('Updating ...');
      this.loadings[0] = true;
      this.submitted = true;
      this.isOnWeb = this.www && this.www && this.www.indexOf('.') === -1;
      if (
        this.rank &&
        this.email.indexOf('@') !== -1 &&
        this.nom &&
        this.NIE &&
        this.address &&
        this.annee &&
        this.regime &&
        this.pays.name &&
        this.formeJuridique &&
        this.domaine.length > 0 &&
        this.fileRegistre !== null &&
        this.fileStatut !== null &&
        this.fileNIE !== null &&
        !this.errorOnFileType
      ) {
        var data = {
          name: this.nom,
          email: this.email,
          address: this.address,
          identificationNumber: this.NIE,
          tradeRegister: this.regime,
          companyCreatedAt: moment(this.annee, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          formeJuridiqueId: this.formeJuridique.id,
          domaineMetiersIds: this.domaine,
          nationId: this.pays.id,
          www: this.www == '' ? null : this.www,
        };
        await http.put('/enterprises/' + this.id, data, this.config).then(
          async () => {
            await this.sendStatut();
            await this.sendNIE();
            await this.sendRegistre();
            //if(!this.haveLogo){
            if (this.changeLogo) await this.sendLogo();
            //}
            await this.confirmform();
            // console.log('***SUCCESSFUL UPDATE DATA*** ' + res);
            this.loadings[0] = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Accomplie',
              detail: 'Votre modification a bien été prise en compte.',
              life: 7000,
            });
          },
          () => {
            // console.log('ERROR ON UPDATE DATA -> ' + error);
            setTimeout(() => (this.loadings[0] = false), 3000);
            this.errorOnSubmit = true;
            this.$toast.add({
              severity: 'error',
              summary: 'Echec',
              detail: "Votre modification n'a pas été prise en compte. Veuillez réessayer.",
              life: 7000,
            });
            setTimeout(() => (this.errorOnSubmit = false), 3000);
          }
        );
        // }
        // else {
        //    console.log('--------------------- not pass 2')
        // }
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: "Votre modification n'a pas été prise en compte. Veuillez réessayer.",
          life: 7000,
        });
        this.isError = true;
        setTimeout(() => (this.isError = false), 3000);
        if (!this.rank) {
          this.errorOnRank = true;
          setTimeout(() => (this.errorOnRank = false), 3000);
        }
        setTimeout(() => (this.loadings[0] = false), 3000);
      }
    },
    async setBack() {
      var data = {
        name: this.nom,
        address: this.address,
        identificationNumber: this.NIE,
        tradeRegister: this.regime,
        companyCreatedAt: moment(this.annee, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        formeJuridiqueId: this.formeJuridique.id,
        domaineMetiersIds: this.domaine,
        nationId: this.pays.id,
        www: this.www == '' ? null : this.www,
        isResubmittingSubscription: true,
      };

      this.submitted = true;
      this.isOnWeb = this.www && this.www && this.www.indexOf('.') === -1;

      if (
        this.rank &&
        this.email.indexOf('@') !== -1 &&
        this.nom &&
        this.address &&
        this.annee &&
        this.regime &&
        this.pays.name &&
        this.formeJuridique &&
        this.domaine.length > 0 &&
        this.fileRegistre !== null &&
        this.fileNIE &&
        this.fileStatut !== null &&
        !this.errorOnFileType
      ) {
        await http.put('/enterprises/' + this.id, data, this.config).then(
          async () => {
            await this.sendStatut();
            await this.sendNIE();
            await this.sendRegistre();
            if (this.changeLogo) await this.sendLogo();
            await this.updateRole();
            this.$toast.add({
              severity: 'success',
              summary: 'Accomplie',
              detail:
                'Votre modification a bien été prise en compte. Le dossier a été retransmis à l’équipe du CNSCL qui examine les renseignements.',
              life: 3000,
            });
          },
          () => {
            this.errorOnSubmit = true;
            this.$toast.add({
              severity: 'error',
              summary: 'Echec',
              detail: "Votre modification n'a pas été prise en compte. Veuillez réessayer.",
              life: 7000,
            });
          }
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: "Votre modification n'a pas été prise en compte. Veuillez réessayer.",
          life: 7000,
        });
        this.isError = true;
        setTimeout(() => (this.isError = false), 3000);
        if (this.isOnWeb) {
          this.www = '';
        }
        setTimeout(() => (this.loadings[0] = false), 3000);
      }
    },
    async updateRole() {
      this.isError = false;
      const token = localStorage.getItem('token');
      const id = this.user.entreprise.abonnements[this.user.entreprise.abonnements.length - 1].id;
      const userPaymentIsValid = this.user.entreprise.isTmpValid;
      const canUpdateRole = userPaymentIsValid;
      if (!canUpdateRole) return;
      try {
        await http.post(
          '/abonnements/' + id + '/setAbonnementValidation',
          {
            statut: 'EN_COURS',
            motifRejet: '',
            roleName: 'USER_VALIDATE',
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        this.$router.push('/');
      } catch (e) {
        console.log(e);
      }
    },
    async getDomaines() {
      await http
        .get('/domaines-metiers?limit=100&sort=libelle,asc', this.config)
        .then((res) => {
          this.domaines = res.data;
          //console.log('*** SUCCESSFUL GET DOMAINE ***' + JSON.stringify(res.data));
        })
        .catch(() => {});
    },
    async getNations() {
      await http
        .get('/countries?limit=300&sort=name&sort=name,asc', this.config)
        .then((res) => {
          this.countries = res.data.data;
          // console.log('*** SUCCESSFUL GET COUNTRIES ***' + JSON.stringify(res.data));
        })
        .catch((e) => {
          console.log('ERROR SUR GET COUNTRIES =>' + e);
        });
    },
    async getFormJuridique() {
      await http
        .get('/formesJuridiques?limit=100', this.config)
        .then((res) => {
          this.formesJuridiques = res.data.data;
        })
        .catch(() => {});
    },
    isFileTypeValid() {
      return (
        this.fileLogo['type'] == 'image/png' ||
        this.fileLogo['type'] == 'image/jpeg' ||
        this.fileLogo['type'] == 'image/jpg'
      );
    },
    onUploadLogo(event) {
      this.fileLogo = event.target.files[0];
      this.changeLogo = true;
      if (
        this.fileLogo['type'] == 'image/png' ||
        this.fileLogo['type'] == 'image/jpeg' ||
        this.fileLogo['type'] == 'image/jpg'
      ) {
        this.errorOnFileType = false;
        this.$toast.add({
          severity: 'info',
          summary: 'Succès',
          detail: 'Le fichier a bien été téléchargé.',
          life: 3000,
        });
        return;
      }
      this.errorOnFileType = true;

      //console.log(JSON.stringify(this.fileLogo));
    },
    onUploadStatus(event) {
      this.$toast.add({
        severity: 'info',
        summary: 'Succès',
        detail: 'Le fichier a bien été téléchargé.',
        life: 3000,
      });
      //this.changeStatut=false;
      this.fileStatut = event.target.files[0];

      if (
        this.fileStatut['type'] == 'image/png' ||
        this.fileStatut['type'] == 'image/jpeg' ||
        this.fileStatut['type'] == 'application/pdf'
      ) {
        this.errorOnFileType = false;
      } else {
        this.errorOnFileType = true;
      }
    },
    async onUploadRegistre(event) {
      this.$toast.add({
        severity: 'info',
        summary: 'Succès',
        detail: 'Le fichier a bien été téléchargé.',
        life: 3000,
      });
      //this.changeRegistre=false;
      this.fileRegistre = event.target.files[0];

      if (
        this.fileRegistre['type'] == 'image/png' ||
        this.fileRegistre['type'] == 'image/jpeg' ||
        this.fileRegistre['type'] == 'application/pdf'
      ) {
        this.errorOnFileType = false;
      } else {
        this.errorOnFileType = true;
      }
    },
    async onUploadNIE(event) {
      this.$toast.add({
        severity: 'info',
        summary: 'Succès',
        detail: 'Le fichier a bien été téléchargé.',
        life: 3000,
      });
      //this.changeNIE=false;
      this.fileNIE = event.target.files[0];

      if (
        this.fileNIE['type'] == 'image/png' ||
        this.fileNIE['type'] == 'image/jpeg' ||
        this.fileNIE['type'] == 'application/pdf'
      ) {
        this.errorOnFileType = false;
      } else {
        this.errorOnFileType = true;
      }
    },
    async sendLogo() {
      let data = new FormData();

      data.append('logoFile', this.fileLogo);

      await http
        .post('/enterprises/' + this.id + '/uploadLogo', data, this.config)
        .then((res) => {
          this.fileName = res.data.fileName;
          this.previewImage = res.data.fileUrl;
        })
        .catch(() => {
          // this.$toast.add({ severity: 'error', summary: 'Echec', detail: "Le fichier (Logo) joint n'est pas accepté. Veuillez joindre un fichier au format .jpg/jpeg, .pdf ou .png.", life: 3000 });
        });
    },
    async sendStatut() {
      let data = new FormData();

      data.append('statutEntrepriseFile', this.fileStatut);

      await http
        .post('/enterprises/' + this.id + '/uploadStatusEntreprise', data, this.config)
        .then((res) => {
          this.fileName = res.data.fileName;
          this.previewImage = res.data.fileUrl;
        })
        .catch(() => {
          // this.$toast.add({ severity: 'error', summary: 'Echec', detail: "Le fichier (Statut de l'entreprise) joint n'est pas accepté. Veuillez joindre un fichier au format .jpg/jpeg, .pdf ou .png.", life: 3000 });
        });
    },
    async sendRegistre() {
      let data = new FormData();

      data.append('registreCommerceFile', this.fileRegistre);

      await http
        .post('/enterprises/' + this.id + '/uploadRegistreCommerce', data, this.config)
        .then(() => {})
        .catch(() => {
          // this.$toast.add({ severity: 'error', summary: 'Echec', detail: "Le fichier (Registre de commerce) joint n'est pas accepté. Veuillez joindre un fichier au format .jpg/jpeg, .pdf ou .png.", life: 3000 });
        });
    },
    async sendNIE() {
      let data = new FormData();

      data.append('numeroIdentificationFile', this.fileNIE);

      await http
        .post('/enterprises/' + this.id + '/uploadNumeroIdentification', data, this.config)
        .then(() => {})
        .catch(() => {
          // this.$toast.add({ severity: 'error', summary: 'Echec', detail: "Le fichier (Document d’identification fiscal) joint n'est pas accepté. Veuillez joindre un fichier au format .jpg/jpeg, .pdf ou .png.", life: 3000 });
        });
    },
    cancelNIE() {
      this.fileNIE = null;
      this.changeNIE = true;
    },
    cancelRegistre() {
      this.fileRegistre = null;
      this.changeRegistre = true;
    },
    cancelStatus() {
      this.fileStatut = '';
      this.changeStatut = true;
    },
    cancelLogo() {
      this.fileLogo = null;
      this.changeLogo = true;
    },
    back() {
      //if (this.role === 'ROLE_TEMP' || this.role === 'USER_VALIDATE' || this.role === 'ROLE_TEMP_REJETE') {
      this.$router.push('/');
      //} else {
      //  this.$router.push('/menu');
      //}
    },
    close(index) {
      this.display[index] = false;
    },
    open(index) {
      this.display[index] = true;
    },
  },
};
</script>

<style scoped lang="scss">
.imagePreviewWrapper {
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #00245a;
}
.text-red {
  color: #db3c30;
}
</style>
